import React,{ Component,useState } from "react"; 
import LoginForm from "./login_page";
import axios from 'axios';
import { Layout, Spin, Modal, notification, Tooltip, Button, Icon,Radio ,ConfigProvider, Popover,Avatar, Divider,Menu, Select ,message} from "antd";
const { confirm } = Modal;
const { Content, Sider } = Layout;
import { login, monitorupdate } from "./api/categories";
import MeetingPage from "./meeting_page";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import VideoIcon from "mdi-react/VideoIcon";
import VideoOffIcon from "mdi-react/VideoOffIcon";
import TelevisionIcon from "mdi-react/TelevisionIcon";
import TelevisionOffIcon from "mdi-react/TelevisionOffIcon";
import BrushOffIcon from "mdi-react/BrushIcon";
import BrushIcon from "mdi-react/BrushIcon";
import BroomIcon from "mdi-react/BroomIcon";
import HangupIcon from "mdi-react/PhoneHangupIcon";
import FullscreenIcon from "mdi-react/FullscreenIcon";
import FullscreenExitIcon from "mdi-react/FullscreenExitIcon";
import CheckboxIntermediateIcon from "mdi-react/CheckboxIntermediateIcon";
import CheckboxBlankOutlineIcon from "mdi-react/CheckboxBlankOutlineIcon";
import ShareMeeting from "./share_meeting";
import PenMeeting from "./pen_meeting";
import SettingsIcon from "mdi-react/SettingsIcon";

import { userUpdate,loginDoctor,addDoctor } from "./api/categories";
// import { mdiTextBoxOutline } from '@mdi/js';





import Report from "./report";
import Doctor from "./doctor";
import MediaSettings from "./setting";
import { v4 as uuidv4 } from "uuid";
import { Client } from "ion-sdk-js";
//import { IonSFUGRPCWebSignal } from 'ion-sdk-js/lib/signal/grpc-web-impl';
import { IonSFUJSONRPCSignal } from "ion-sdk-js/lib/signal/json-rpc-impl";
import { reactLocalStorage } from "reactjs-localstorage";
import UserList from "./user";
import ChatFeed from "./chat";
import Message from "./chat/message";
import "../styles/css/app.scss";
import { ZhihuCircleFilled } from "@ant-design/icons/lib/icons";
import { AntDesignOutlined, UserOutlined,DeploymentUnitOutlined,FundProjectionScreenOutlined,PartitionOutlined,FolderAddOutlined,SisternodeOutlined,VideoCameraAddOutlined  ,PicRightOutlined} from '@ant-design/icons';
import { InteractionOutlined} from '@ant-design/icons';
// <InteractionOutlined /><FileSearchOutlined />
const MyContext = React.createContext(); 
const { SubMenu } = Menu;
import JSZip from 'jszip';
import { AliRTSPusher } from 'aliyun-rts-pusher';
class Samples extends React.Component {
	
  constructor(props) {
    super(props);
	
    this.state = {
		MeshOnline: false,
		MirrorOnline: false,
		DesktopOnline: false,
		
		DisMeshOnline: false,
		DisMirrorOnline: false,
		DisDesktopOnline: false,
		
		isModalOpen: false,
		isModalOpenLogin: false,
		 dshowModal: false,
		 dname: '',
		 dphoneNumber: '',
		 dpassword: '',
		isDisabled:false, 
		 progress: '', // 显示进度
		  isVisible: false, // 添加这个状态
		selectedFile: null,
		zipList:[],                                                            
		    
		    uploadProgress: 0,
			 totalChunks: 0,          // Total number of chunks
			    uploadedChunks: 0,       // Number of chunks successfully uploaded
			    uploadStatus: '', 
		rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
		hostState:false,
		hostStatelocal:false,
		openKeys: [],
	  streams: [],
      login: true,
      loading: false,
      collapsed: false,
      loginInfo: {},
      uid: "",
      roomId: "",
      name: "",
      users: [],
      messages: [],
      localAudioEnabled: true,
      localVideoEnabled: true,
      localVideoEnabledBig: sessionStorage.getItem('bigvideo')==0?false:true,
      screenSharingEnabled: false,
      vidFit: false,
      brush: false,
      isFullScreen1: false,
      isFullScreen: false,
	   selectedOption: false,
	   svsList:[],
	    isLooping: false,
	    LoopingText: "",
    };

    this.settings = {
      selectedAudioDevice: "",
      selectedVideoDevice: "",
	  selectedVideoDeviceLocal: "",
      resolution: "hd",
      bandwidth: 900,
      codec: "H264",
    };
	
    let settings = reactLocalStorage.getObject("settings");
    if (settings.codec !== undefined) {
      this.settings = settings;
    }
	
  }
  startLoop = (msg) => {
      this.setState({ isLooping: true,LoopingText:msg}, () => {
            this.loopFunction();
      });
    };
  
    stopLoop = () => {
      this.setState({ isLooping: false ,LoopingText:""});
    };
  
    loopFunction = () => {
      if (this.state.isLooping) {
        // 在这里执行您想要循环执行的逻辑
        this.onSendMessage(this.state.LoopingText); // 示例调用 onSendMessage 函数
  
        // 设置下一个循环执行的时间间隔，这里设置为1秒
        setTimeout(this.loopFunction, 1500);
      }
    };
  handleMouseEnter = () => {
      this.setState({ isVisible: true });
    }
  
    handleMouseLeave = () => {
      this.setState({ isVisible: false });
    }
  getImage = (e) => {
   
     this.meeting.bigVideo.takeSnap()
  }
  getReport= (e) => {
	
     this.report.getReport()
  }
  UNSAFE_componentWillMount() {
	  
	  let roomId1=this.GetRequest2('room')
	  if(roomId1!=""){
		  this.handleLogin({
		    roomId: roomId1,
		    displayName: sessionStorage.getItem("userId")
		      ? sessionStorage.getItem("userId")
		      : "admin",
		  });
	  }else{
		  if(sessionStorage.getItem('roomId')!=""&&sessionStorage.getItem('roomId')!=null){
		  	this.handleLogin({
		  	  roomId: sessionStorage.getItem('roomId'),
		  	  displayName: sessionStorage.getItem("userId")
		  	    ? sessionStorage.getItem("userId")
		  	    : "admin",
		  	});
		  }else {
		  	this.setState({ login: false });
		  	window.location.href = "https://test.xytechsz.com/";
		  	history.go(0)
		  }
		  
	  }
    
  }
   GetRequest2(key){
      var url = window.location.hash;  
      var para = url; /* 获取属性（“?”后面的分段） */
      para=para.toString().substring(15);
     
      return para;
   }
   
  handleLogin = async (values) => {
    let uid = uuidv4();
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        roomId: values.roomId,
        name: values.displayName,
      },
      () => {
        // var url = "wss://xytechsz.virgilboss.top/meeting_ws";
        var url = "wss://auser.xytechsz.com/meeting_ws";
		// var url = "wss://113.88.242.78:8001/ws";
		
		
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
          console.log("WebSocket连接成功....");
          let message = {
            type: "joinRoom",
            data: {
              name: this.state.name,
              id: uid,
              roomId: values.roomId,
            },
          };
          this.send(message);
        };

        this.socket.onmessage = (e) => {
          var message = JSON.parse(e.data);
          // console.log(
          //   "收到的消息:{\n type= " +
          //     message.type +
          //     ", \n data =  " +
          //     JSON.stringify(message.data) +
          //     "\n"
          // );
		  
		  
			
          switch (message.type) {
            case "userJoin":
              break;
            case "leaveRoom":
              break;
            case "publicChat":
              if (message.data.text == "drawLine2") {
                if(message.data.name== sessionStorage.getItem("userName")&&message.data.token!= sessionStorage.getItem("token")){
              					this.removeCookie("logostatelocal");
              					 sessionStorage.clear();
                     window.location.href = "https://test.xytechsz.com/"; 
                }
                
              } else{
              				  if (JSON.parse(message.data.text).type == "drawLine") {
              				    if(message.data.name!=sessionStorage.getItem("userName")){
              				        this.meeting.bigVideo.drawCanvas(
              				            message.data.text,
              				            message.data.colorType,
              				            message.data.penZize,
              				           
              				          );
              				    }
              				    
              				  } else if (JSON.parse(message.data.text).type == "clearCanvas") {
              				    this.meeting.bigVideo.clearCanvas2();
              				  }else if(JSON.parse(message.data.text).type == "show"){
								   sessionStorage.setItem(JSON.parse(JSON.parse(message.data.text).data).streamid,JSON.parse(JSON.parse(message.data.text).data).userName);
								   
								}
								else if(JSON.parse(message.data.text).type == "mainshow"){
										
										this.meeting.onChangeVideoPositionSam(JSON.parse(JSON.parse(message.data.text).data).mainId)
										
										if(JSON.parse(JSON.parse(message.data.text).data).userName!=sessionStorage.getItem("userName")){
											// this.state.selectedOption=false
											this.setState({
											  selectedOption: false,
											});
											try {
												this.meeting.bigVideo.handlehide()
											} catch (error) {
												
											}
											
											try {
												this.meeting.localVideo.handlehide()
											} catch (error) {
												
											}
													
													
													try {
														this.meeting.handlehide()
													} catch (error) {
														
													}
											this.setState({ isFullScreen1: true });
										}
								
								}
              }
              //判断是否为清除 ，如果是清除
              // this.meeting.bigVideo.clearCanvas()
              // 如果不是清除就画点
              // this.meeting.bigVideo.drawCanvas()
              break;
            case "heartPackage":
              console.log('服务端发心跳包!');
              break;
            case "updateUserList":
              let users = message.data;
              this.setState({
                users: users,
              });
              break;
          }
        };

        this.socket.onerror = (e) => {
          console.log("onerror::" + e.data);
        };

        this.socket.onclose = (e) => {
          console.log("onclose::" + e.data);
        };

        const signal = new IonSFUJSONRPCSignal(
          // "wss://xytechsz.virgilboss.top/meeting_sfu"
          // "wss://whost.xytechsz.com/meeting_sfu"
		  "wss://auser.xytechsz.com/meeting_sfu"
        );
        const client = new Client(signal);
        this.client = client;
        signal.onopen = () => {
          client.join(this.state.roomId, this.state.userName);
          this.onJoin(values, uid);
        };
      }
    );
  };

  onJoin = async (values, uid) => {
    reactLocalStorage.remove("loginInfo");
    reactLocalStorage.setObject("loginInfo", values);
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        loginInfo: values,
      },
      () => {
		  
		  
        this.meeting.publishLocalStream ();
		this.onSendUserMessage("drawLine2");
		
		
		
      }
    );
  };
  ck(){
	  
  }
  showMain(){
  	  if(sessionStorage.getItem("streamsobj1")){
  		  this.onSendMessage(JSON.stringify({
  	  type:"show",
  	  data:sessionStorage.getItem("streamsobj1")
  	  }))}
  	  if(sessionStorage.getItem("ShareScreen")){
  	  		  this.onSendMessage(JSON.stringify({
  	  type:"show",
  	  data:sessionStorage.getItem("ShareScreen")
  	  }))}
  	  if(sessionStorage.getItem("streamsobj2")){
  	  		  this.onSendMessage(JSON.stringify({
  	  type:"show",
  	  data:sessionStorage.getItem("streamsobj2")
  	  }))}
  	  if(this.state.selectedOption==true&&this.meeting.state.streams[0].id){
  	  	this.onSendMessage(JSON.stringify({
  	  	type:"mainshow",
  	  	data:JSON.stringify({
  			userName:sessionStorage.getItem("userName"),
  			mainId:this.meeting.state.streams[0].id
  		})
  		//显示userName  发送中间id  主持人
  	  	}))
  	  }
  	  
  	  
  	  
  }
  
	changeFullScreenClickHandler2 = async () => {
		
		 this.onFullScreenClickHandler2()
	};
  getCookie=(name)=>{
      var arr=document.cookie.split('; ');
      var i=0;
      for(i=0;i<arr.length;i++)
      {
          var arr2=arr[i].split('=');
          
          if(arr2[0]==name)
          {
              return arr2[1];
          }
      }
      return '';//如果没有就返回空值
  }
  componentDidMount(){
	  this.updateSvsList()
	  this.changeFullScreenClickHandler2()
	  if(sessionStorage.getItem("yk")==1){
	  	this.onFullScreenClickHandler2()
	  }
	  else{
		  this.timerID = setInterval(
		      () => {
		  				  if(sessionStorage.getItem("streamsobj1")){
		  				  		  this.onSendMessage(JSON.stringify({
		  				  type:"show",
		  				  data:sessionStorage.getItem("streamsobj1")
		  				  }))}
		  				  if(sessionStorage.getItem("ShareScreen")){
		  				  		  this.onSendMessage(JSON.stringify({
		  				  type:"show",
		  				  data:sessionStorage.getItem("ShareScreen")
		  				  }))}
		  				  if(sessionStorage.getItem("streamsobj2")){
		  				  		  this.onSendMessage(JSON.stringify({
		  				  type:"show",
		  				  data:sessionStorage.getItem("streamsobj2")
		  				  }))}
		  				
		  				  var difValue = new Date().getTime() - sessionStorage.getItem("toMeetingTime");
		  				  var day = Math.floor(difValue / 1000 / 60 / 60 / 24);//天
		  				  difValue = difValue % (1000 * 60 * 60 * 24);
		  				  var hour = Math.floor(difValue / 1000 / 60 / 60);//小时
		  				  difValue = difValue % (1000 * 60 * 60);
		  				  var min = Math.floor(difValue / 1000 / 60);//分钟
		  				  difValue = difValue % (1000 * 60);
		  				  var second = Math.floor(difValue / 1000);
		  				  if(hour>=2){
		  					  
		  					   clearInterval(this.timerID)
		  					   sessionStorage.clear();
		  					  console.log(this.getDifValue(new Date().getTime(),sessionStorage.getItem("toMeetingTime")))
		  					   message.success('会议用时超过2小时，如需继续会议，请重新登录', 10);
		  					   setInterval(
		  					       () => {
		  							    window.location.href = "https://test.xytechsz.com/"; 
		  						   },
		  							5000
		  						);
		  					  
		  					
		  					 
		  				  }
		  			  },
		      1000
		  );
		       
	  }
       
	  }
  
      componentWillUnmount(){
          clearInterval(this.timerID)
      }
	onSendUserMessage = (msg) => {
	  let message = {
	    type: "publicChat",
	    data: {
	      name: sessionStorage.getItem("userName"),
	      id: this.state.uid,
	      roomId: this.state.roomId,
	      text: msg,
	      token: sessionStorage.getItem("token"),
	     
	    },
	  };
	  this.send(message);
	};
  onSendMessage = (msg) => {
    let message = {
      type: "publicChat",
      data: {
		  
        name: sessionStorage.getItem("userName"),
        id: this.state.uid,
        roomId: this.state.roomId,
        text: msg,
        colorType: sessionStorage.getItem("color"),
        penZize: sessionStorage.getItem("pensize"),
      },
    };
    this.send(message);
  }; 
  onSystemMessage = (msg) => {
    let messages = this.state.messages;
    let uid = 2;
    messages.push(new Message({ id: uid, message: msg, senderName: "System" }));
    this.setState({ messages });
  };

  showMessage = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: "topRight",
    });
  };

  send = (data) => {
    this.socket.send(JSON.stringify(data));
  };
  showManege() {
    window.location.href = "https://amanage.xytechsz.com/";
  }
  handleAudioTrackEnabled = (enabled) => {
    this.setState({
      localAudioEnabled: enabled,
    });
    this.meeting.muteMediaTrack("audio", enabled);
  };

  handleVideoTrackEnabled = (enabled) => {
    this.setState({
      localVideoEnabled: enabled,
    });
    this.meeting.muteMediaTrack("video", enabled);
  };
	
	handleVideoTrackEnabledBig = (enabled) => {
		
    console.log(enabled)
    if(enabled==true){
      sessionStorage.setItem("bigvideo",1);
    }else{
      sessionStorage.setItem("bigvideo",0);

    }
   this.meeting.toggleCameraAndMicrophone2(!enabled)
	  this.setState({
	    localVideoEnabledBig: enabled,
	  });
  
	  // this.meeting.muteMediaTrackBig("video", enabled);
	};
  handleScreenSharing = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
      // localVideoEnabled:!enabled
    });
    this.meeting.handleScreenSharing(enabled);
    // this.meeting.muteMediaTrack("video", !enabled);
  };

  onScreenSharingCallBack = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
    });
  };

  openOrCloseLeftContainer = (collapsed) => {
    this.setState({
      collapsed: collapsed,
    });
  };

  cleanUp = async () => {
	  
    await this.meeting.cleanUp();
  };
  setCookie = (name, value, iDay) => {
    var oDate = new Date();

    oDate.setDate(oDate.getDate() + iDay);

    document.cookie = name + "=" + value + ";expires=" + oDate;
  };
 
  removeCookie = (name) => {
    this.setCookie(name, 0, -1); //将过期时间设置为昨天，系统就自动删除了
  };
  userMonitor = async () => {
    this.removeCookie("logostatelocal");
    let monitorId = sessionStorage.getItem("monitorId");
    console.log(monitorId);
    if (monitorId) {
      console.log(monitorId);
      const res = await monitorupdate({
        id: monitorId,
      });
      console.log(res);
    }
  };
  
    getDifValue(nowtimestamp, beforetimestamp){
              var difValue = nowtimestamp - beforetimestamp;
			 
              var day = Math.floor(difValue / 1000 / 60 / 60 / 24);//天
              difValue = difValue % (1000 * 60 * 60 * 24);
              var hour = Math.floor(difValue / 1000 / 60 / 60);//小时
              difValue = difValue % (1000 * 60 * 60);
              var min = Math.floor(difValue / 1000 / 60);//分钟
              difValue = difValue % (1000 * 60);
              var second = Math.floor(difValue / 1000);
			  var money=difValue/(1000*60)*0.3
			  const minutesDiff = Math.floor(difValue / (1000 * 60));
			  const times1 = sessionStorage.getItem("times")-min;
			  console.log(min)
			   return "用时:" + day + "年" + hour + "小时" + min + "分钟" + second + "秒"+"   "+"产生了"+money.toFixed()+"元,剩余"+times1+"分钟"
          }
         

  hangUp = async () => {
    const that = this;
    this.userMonitor();
	if(sessionStorage.getItem("yk")==1){
		window.location.href = "https://test.xytechsz.com/";
		history.go(0)
	}
	else{
		confirm({
		  title: "退出？",
		  content: this.getDifValue(new Date().getTime(),sessionStorage.getItem("toMeetingTime")) + "  是否退出会议?",
		  okText: "确定",
		  cancelText: "取消",
		  async onOk() {
		    await that.cleanUp();
			sessionStorage.removeItem("doctorId");
			const timeDiff =new Date().getTime()-sessionStorage.getItem("toMeetingTime");
			
			// 将时间差转换为分钟并取整
			const minutesDiff = Math.floor(timeDiff / (1000 * 60));
			const times1 = sessionStorage.getItem("times")-minutesDiff;
			sessionStorage.setItem("times",times1);
			
			const res = await userUpdate({
			  id: sessionStorage.getItem("userId"),
			  times:times1,
			});
			console.log(res);
			if (res.code == "200") {
			  that.setState({ login: false });
			  window.location.href = "https://test.xytechsz.com/#/RoomList";
			  // window.location.href = "https://localhost:8080/#/RoomList";
			  history.go(0)
			} else {
			  that.setState({ login: false });
			  window.location.href = "https://test.xytechsz.com/#/RoomList";
			  // window.location.href = "https://localhost:8080/#/RoomList";
			  history.go(0)
			}
		    
		    // window.location.href = "https://alocal.xytechsz.com#/RoomList";
		  },
		  onCancel() {},
		});
	}
    
  };

  onVideodFitClickHandler = () => {
    this.setState({
      vidFit: !this.state.vidFit,
    });
  };
  onBrushClickHandler = () => {
    this.setState({
      brush: !this.state.brush,
    });
    this.meeting.bigVideo.setDrawState();
  };
	
  onBroomClickHandler = () => {
  
    this.meeting.bigVideo.clearCanvas();
  };
  onFullScreenClickHandler = () => {
    let docElm = document.documentElement;
	
    if (this.state.isFullScreen1) {
		try {
			this.meeting.bigVideo.handleshow()
		} catch (error) {
			
		}
		
		
		try {
			this.meeting.localVideo.handleshow()
		} catch (error) {
			
		}
		
		try {
				this.meeting.handleshow()
		} catch (error) {
			
		}
		
  //     if (document.exitFullscreen) {
		//   console.log(2)
		// this.meeting.bigVideo.handleshow()
  //     } 

      this.setState({ isFullScreen1: false });
    } else {
		try {
			this.meeting.bigVideo.handlehide()
		} catch (error) {
			
		}
		
		try {
			this.meeting.localVideo.handlehide()
		} catch (error) {
			
		}
		try {
				this.meeting.handlehide()
		} catch (error) {
			
		}
	
     
      // this.setState({ isFullScreen: true });
      this.setState({ isFullScreen1: true });
    }
  };
	  
  
	onFullScreenClickHandler2 = () => {
	  let docElm = document.documentElement;
		
	  if (this.fullscreenState()) {
			// this.meeting.bigVideo.handleshow()
			// this.meeting.localVideo.handleshow()
			// this.meeting.handleshow()
			console.log(1)
	    if (document.exitFullscreen) {
			  console.log(2)
			// this.meeting.bigVideo.handleshow()
	      document.exitFullscreen();
	    } else if (document.mozCancelFullScreen) {
			   console.log(3)
	      document.mozCancelFullScreen();
	    } else if (document.webkitCancelFullScreen) {
	      document.webkitCancelFullScreen();
	    } else if (document.msExitFullscreen) {
	      document.msExitFullscreen();
	    }
	
	    this.setState({ isFullScreen: false });
	  } else {
			// this.meeting.bigVideo.handlehide()
			// this.meeting.localVideo.handlehide()
			// this.meeting.handlehide()
	    if (docElm.requestFullscreen) {
			 
			try {
				docElm.requestFullscreen();
			} catch (error) {
				
			}
	      
		  
	    }
	    //FireFox
	    else if (docElm.mozRequestFullScreen) {
	      docElm.mozRequestFullScreen();
	    }
	    //Chrome等
	    else if (docElm.webkitRequestFullScreen) {
			    console.log(10120)
	      docElm.webkitRequestFullScreen();
	    }
	    //IE11
	    else if (elem.msRequestFullscreen) {
	      elem.msRequestFullscreen();
	    }
			
	    this.setState({ isFullScreen: true });
	  }
	};
  fullscreenState = () => {
    return (
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      false
    );
  };
  
  //共享桌面
  gx = () => {
	  const that=this
	 this.setState(prevState => ({
	   selectedOption: true, // 切换状态
	   hostStatelocal: true // 切换状态
	 }), () => {
	 	this.onFullScreenClickHandler()
	   // 
	 
	  
			   
	  
	   
	   
	 }); 
  }
       
  ungx = () => {
  	  const that=this
  	 this.setState(prevState => ({
  	   selectedOption: false, // 切换状态
  	   hostStatelocal: false // 切换状态
  	 }), () => {
  	 	this.onFullScreenClickHandler()
  	   // 
  	 
  	  
  			   
  	  
  	   
  	   
  	 }); 
  }
  
 handleCancelSelection = (e) => {
		this.setState(prevState => ({
		  selectedOption: !prevState.selectedOption, // 切换状态
		  hostStatelocal: !prevState.hostStatelocal // 切换状态
		}), () => {
			// this.onFullScreenClickHandler()
		  // 
		  this.showMain()
		  
		 
		  
		});
		
		
	
	}
	
	
	MeshOnlineClick = (e) => {
		
			this.setState(prevState => ({
			  MeshOnline: !prevState.MeshOnline, // 切换状态
			}), () => {
				if(this.state.MeshOnline){
					this.client.publish(this.meeting.state.localStream)
					
					
				}else{
					this.meeting.state.localStream.unpublish()
				}
			});
		}
	MirrorOnlineClick = (e) => {
			this.setState(prevState => ({
			  MirrorOnline: !prevState.MirrorOnline, // 切换状态
			}), () => {
				if(this.state.MirrorOnline){
					this.client.publish(this.meeting.state.localStream2)
					
					
				}else{
					this.meeting.state.localStream2.unpublish()
				}
				
			});
		}	
	DesktopOnlineClick = (e) => {
			this.setState(prevState => ({
			  DesktopOnline: !prevState.DesktopOnline, // 切换状态
			}), () => {
				if(this.state.DesktopOnline){
					this.client.publish(this.meeting.state.localScreen)
					
					
				}else{
					this.meeting.state.localScreen.unpublish()
				}
			});
		}	
	handleFileChange = (event) => {
	    this.setState({
	      selectedFile: event.target.files[0],
	      uploadStatus: '',
		     uploadProgress: 0, 
	    }, () => {
	      // 在状态更新完成后执行的函数
	     this.handleFileUpload()
	    });
		
	  };
	  handleFileChange2 = async (event) => {
	    try {
	      // 获取文件列表
	      const fileList = event.target.files;
	  
	      if (fileList.length === 0) {
	        console.error('未选择任何文件');
	        return;
	      }
			this.setState({ uploadStatus: '',
	        uploadProgress: 0,});
	        // 从第一个文件的路径中提取文件夹名称
	          const firstFilePath = fileList[0].webkitRelativePath;
	          const baseName = firstFilePath.split('/')[0]; // 从路径中提取文件夹名称
	  
	  //     // 创建一个新的 JSZip 实例
	  //     const zip = new JSZip();
	  
	  //     // 遍历文件列表并将每个文件添加到 zip 实例中
	  //     Array.from(fileList).forEach((file) => {
	  //       zip.file(file.name, file, { binary: true });
	  //     });
	  
	  //     // 生成一个包含所有文件的压缩 blob
	  //     const blob = await zip.generateAsync({ type: 'blob' });
	  
	  //     // 创建一个 File 对象，使用原文件名加上 `.zip` 扩展名
	  //     const compressedFile = new File([blob], `${baseName}.zip`, { type: 'application/zip' });
			
			
			const zip = new JSZip();
			
			  // 遍历文件列表并将每个文件添加到 zip 实例中
			  Array.from(fileList).forEach((file) => {
			    // 使用 file.webkitRelativePath 或 file.relativePath 获取文件的相对路径
			    const relativePath = file.webkitRelativePath || file.relativePath || file.name;
			    zip.file(relativePath, file, { binary: true });
			  });
			
			  // 生成一个包含所有文件的压缩 blob
			  const blob = await zip.generateAsync({ type: 'blob', compression: 'STORE' }); // 使用 'STORE' 不压缩文件
			
			  // 创建一个 File 对象，使用原文件名加上 `.zip` 扩展名
			  const compressedFile = new File([blob], `${baseName}.zip`, { type: 'application/zip' });
			
	      // 更新状态
	      this.setState({
	        selectedFile: compressedFile,
	      
	      }, () => {
	        // 状态更新完成后调用上传方法
	        this.handleFileUpload2();
	      });
	  
	    } catch (error) {
	      console.error('处理文件时出错:', error);
	      // 你可以在这里处理错误，比如设置状态或显示错误消息
	    }
	  };
	  handleDelete(filePath) {
	      // Perform delete operation using fetch or axios
	      fetch(`https://amanage.xytechsz.com/manage1/linuxFile/deleteSvsFile/${filePath}`, {
	          method: 'DELETE'
	      })
	      .then(response => response.json())
	      .then(data => {
			  this.updateSvsList()
	          
	      })
	      .catch(error => {
	          console.error('Error deleting file:', error);
	          // Handle error (e.g., show error message to user)
	      });
	  }
	 
	    
	  handleDownloadWindows = async (filePath) => {
		   
		     
			 try {
		          // 发送请求到后端获取下载链接
				 message.success('下载压缩中，请勿重复操作', 6);
		          const response = await axios.get('https://xysvs.xytechsz.com/manage1/file/downloadZip', {
		            params: { folderPath: filePath }
		          });
				 
		          if (response.code=="200") {
					 const url = `https://xysvs.xytechsz.com/manage1/file/windowsDownload?filePath=${encodeURIComponent(filePath+".zip")}`;
					  
		           window.open(url, '_blank');
		          } else {
					
		          }
		        } catch (err) {
		       
		        } finally {
		          
		        }
		     
		       // window.open(url, '_blank');
	     
	     
	  }
	  handleDownloadLinux(filePath) {
	  		    const url = `https://amanage.xytechsz.com/manage1/file/download?filePath=${encodeURIComponent(filePath)}`;
	  		       
	  		       // 使用 window.open 进行文件下载
	  		       window.open(url, '_blank');
	      // Perform delete operation using fetch or axios
	     
	  }
	  handleDeleteLinux(filePath) {
	      // Perform delete operation using fetch or axios
			
	      fetch(`https://amanage.xytechsz.com/manage1/linuxFile/deleteSvsFile?filePath=${encodeURIComponent(filePath)}`, {
	          method: 'DELETE',
	      })
	      .then(response => response.json())
	      .then(data => {
	          if (data.code=="200") {
	              // 文件删除成功
	             this.updateSvsList(); // 更新文件列表
	          } else {
	              // 文件删除失败
	          }
	      })
	      .catch(error => {
	          console.error('Error deleting file:', error);
	          // 处理错误
	          alert('删除文件时发生错误'); // 显示错误信息
	      });
	  }
	  handleDeletewindows(filePath) {
	      // Perform delete operation using fetch or axios
	      
	      fetch(`https://xysvs.xytechsz.com/manage1/file/deleteZipFile?filePath=${encodeURIComponent(filePath)}`, {
	          method: 'DELETE',
	      })
	      .then(response => response.json())
	      .then(data => {
	          if (data.code=="200") {
	              // 文件删除成功
	              this.updateZipList(); // 更新文件列表
	          } else {
	              // 文件删除失败
	          }
	      })
	      .catch(error => {
	          console.error('Error deleting file:', error);
	          // 处理错误
	          alert('删除文件时发生错误'); // 显示错误信息
	      });
	  }
	  handleFileUpload = () => {
	    const { selectedFile } = this.state;
	    const fileChunkSize = 1 * 1024 * 1024; // 2MB per chunk
	    const totalChunks = Math.ceil(selectedFile.size / fileChunkSize);
	    this.setState({ totalChunks });
	  
	    const chunkPromises = [];
	    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
	      const startByte = chunkIndex * fileChunkSize;
	      const chunk = selectedFile.slice(startByte, startByte + fileChunkSize);
	      const formData = new FormData();
	      formData.append('file', chunk, `${selectedFile.name}-${chunkIndex}`);
	      formData.append('fileName', selectedFile.name);
	      formData.append('chunkIndex', chunkIndex); // Add chunkIndex parameter
	      formData.append('totalChunks', totalChunks); // Add totalChunks parameter
	  
	      const uploadPromise = axios.post('https://amanage.xytechsz.com/manage1/linuxFile/uploadChunk', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data',
	        },
	        onUploadProgress: (progressEvent) => {
	          const { uploadedChunks } = this.state;
	          const currentProgress = Math.round(((uploadedChunks * 100) + (progressEvent.loaded * 100 / progressEvent.total)) / totalChunks);
	          console.log(`Uploaded chunk ${uploadedChunks + 1}/${totalChunks}: ${currentProgress}%`);
	          this.setState({ uploadProgress: currentProgress });
	        },
	      });
	  
	      uploadPromise.then(() => {
	        this.setState((prevState) => ({
	          uploadedChunks: prevState.uploadedChunks + 1,
	        }));
	      });
	  
	      chunkPromises.push(uploadPromise);
	    }
	  
	    Promise.all(chunkPromises)
	      .then(() => {
	        return axios.post('https://amanage.xytechsz.com/manage1/linuxFile/mergeChunks', JSON.stringify({
	          fileName: selectedFile.name,
	          totalChunks: totalChunks,
			  roomName:sessionStorage.getItem("roomName")
	        }), {
	          headers: {
	            'Content-Type': 'application/json',
	          },
	        });
	      })
	      .then((mergeResponse) => {
	        console.log('File merged successfully:', mergeResponse.data);
	        this.setState({ uploadStatus: '文件上传成功!', uploadProgress: 0 });
	       
	        this.updateSvsList();
	      })
	      .catch((error) => {
	        console.error('Error uploading file:', error);
	        this.setState({ uploadStatus: '文件上传失败.', uploadProgress: 0 });
	      });
	  };
	handleFileUpload2 = () => {
		
		
	  const { selectedFile } = this.state;
	  const fileChunkSize = 1 * 1024 * 1024; // 2MB per chunk
	  const totalChunks = Math.ceil(selectedFile.size / fileChunkSize);
	  this.setState({ totalChunks });
	
	  const chunkPromises = [];
	  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
	    const startByte = chunkIndex * fileChunkSize;
	    const chunk = selectedFile.slice(startByte, startByte + fileChunkSize);
	    const formData = new FormData();
	    formData.append('file', chunk, `${selectedFile.name}-${chunkIndex}`);
	    formData.append('fileName', selectedFile.name);
	    formData.append('chunkIndex', chunkIndex); // Add chunkIndex parameter
	    formData.append('totalChunks', totalChunks); // Add totalChunks parameter
	
	    const uploadPromise = axios.post('https://xysvs.xytechsz.com/manage1/file/uploadChunk', formData, {
	    // const uploadPromise = axios.post('https://virgil.goho.co/file/uploadChunk', formData, {
	      headers: {
	        'Content-Type': 'multipart/form-data',
	      },
	      onUploadProgress: (progressEvent) => {
	        const { uploadedChunks } = this.state;
	        const currentProgress = Math.round(((uploadedChunks * 100) + (progressEvent.loaded * 100 / progressEvent.total)) / totalChunks);
	        console.log(`Uploaded chunk ${uploadedChunks + 1}/${totalChunks}: ${currentProgress}%`);
	        this.setState({ uploadProgress: currentProgress });
	      },
	    });
	
	    uploadPromise.then(() => {
	      this.setState((prevState) => ({
	        uploadedChunks: prevState.uploadedChunks + 1,
	      }));
	    });
	
	    chunkPromises.push(uploadPromise);
	  }
	
	  Promise.all(chunkPromises)
	    .then(() => {
	      // return axios.post('https://virgil.goho.co/file/mergeChunks2', JSON.stringify({
	      return axios.post('https://xysvs.xytechsz.com/manage1/file/mergeChunks2', JSON.stringify({
	        fileName: selectedFile.name,
	        totalChunks: totalChunks,
	      }), {
	        headers: {
	          'Content-Type': 'application/json',
	        },
	      });
	    })
	    .then((mergeResponse) => {
	      console.log('File merged successfully:', mergeResponse.data);
	      this.setState({ uploadStatus: '文件上传成功!', uploadProgress: 0 });
	     
	      this.updateSvsList();
	    })
	    .catch((error) => {
	      console.error('Error uploading file:', error);
	      this.setState({ uploadStatus: '文件上传失败.', uploadProgress: 0 });
	    });
	};
	handleChange = (e) => {
	 
	   
	}
	updateZipList=(e)=>{
		
		// axios.get('https://xysvs.xytechsz.com/manage1/file/zipList')
		axios.get('https://xysvs.xytechsz.com/manage1/file/zipList')
		   .then((response) => {
		    
		     this.setState({
				 zipList:response.data
			 })
		   })
		   .catch((error) => {
		    
		   });
		
	}
	updateSvsList=(e)=>{
		// axios.get('https://amanage.xytechsz.com/manage1/linuxFile/svsList')
		// axios.get('https://virgil.goho.co/file/svsList')
		axios.get('https://amanage.xytechsz.com/manage1/linuxFile/svsList', {
		params: {
				 roomName:sessionStorage.getItem("roomName")
				}
			})
			.then((response) => {
		    
		     this.setState({
				 svsList:response.data
			 })
		   })
		   .catch((error) => {
		    
		   });
		
		this.updateZipList()
	}
	 onOpenChange = openKeys => {
		 console.log("sss")
	     const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
	     if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
	       this.setState({ openKeys });
	     } else {
	       this.setState({
	         openKeys: latestOpenKey ? [latestOpenKey] : [],
	       });
	     }
	   }
  onMediaSettingsChanged = (
    selectedAudioDevice,
    selectedVideoDevice,
    selectedVideoDeviceLocal,
    resolution,
    bandwidth,
    codec
  ) => {
    this.settings = {
      selectedAudioDevice,
      selectedVideoDevice,
	  selectedVideoDeviceLocal,
      resolution,
      bandwidth,
      codec,
    };
    reactLocalStorage.setObject("settings", this.settings);
  };
  extractAfterLastUnderscore0=(filePath)=> {
    
    // 使用 lastIndexOf 寻找最后一个斜杠的位置
        const lastSlashIndex = filePath.lastIndexOf('/');
    
        if (lastSlashIndex !== -1) {
            // 使用 substring 截取最后一个斜杠后的字符串
            const extractedString = filePath.substring(lastSlashIndex + 1);
            return extractedString;
        } else {
            return "未找到斜杠";
        }
  }
	extractAfterLastUnderscore=(filePath)=> {
	  // 使用 lastIndexOf 寻找最后一个下划线的位置
	  const lastUnderscoreIndex = filePath.lastIndexOf('\\');
	
	  if (lastUnderscoreIndex !== -1) {
	    // 使用 substring 截取最后一个下划线后的字符串
	    const extractedString = filePath.substring(lastUnderscoreIndex + 1);
	    return extractedString;
	  } else {
	    return "未找到下划线";
	  }
	}
	extractAfterLastUnderscore2=(filePath)=> {
	  // 使用 lastIndexOf 寻找最后一个下划线的位置
	  const lastUnderscoreIndex = filePath.replace(/\\|:/g, '*');
	  
	  return  'http://xyzb.xytechsz.com/'+lastUnderscoreIndex;
	}
	extractAfterLastUnderscore3=(filePath)=> {
	  
	  return  'http://xyzb.xytechsz.com/'+sessionStorage.getItem("roomName")+"/"+filePath;
	}
	
	 showModal = () => {
	    this.setState({ isModalOpen: true });
	  };
	  showModalLogin = () => {
	     this.setState({ isModalOpenLogin: true });
	   };
	hideModalLogin = () => {
	   this.setState({ isModalOpenLogin: false });
	 };
	hideModal = () => {
	   this.setState({ isModalOpen: false });
	 };
	  handleOk = () => {
	    this.setState({ isModalOpen: false });
	  };
	
	handleCancelLogin = () => {
		 this.setState({ name: '', dphoneNumber: '' ,dpassword:""});
	  this.setState({ isModalOpenLogin: false });
	};
	  handleCancel = () => {
		   this.setState({ name: '', phoneNumber: '' ,dpassword:""});
	    this.setState({ isModalOpen: false });
	  };
	  
	  
	  dhandleNameChange = (e) => {
	    this.setState({ dname: e.target.value });
	  };
	
	  dhandlePhoneNumberChange = (e) => {
	    this.setState({ dphoneNumber: e.target.value });
	  };
	dhandleSubmitLogin = async(e) => {
		
		
	    e.preventDefault();
	    // 在这里可以处理提交逻辑，比如将姓名和手机号码发送到后端
	    console.log('姓名:', this.state.dname);
	    console.log('手机号码:', this.state.dphoneNumber);
	    console.log('密码', this.state.dpassword);
	    // 清空输入框
		const res = await loginDoctor({
		  phone: this.state.dphoneNumber,
		  password: this.state.dpassword,
		});
		console.log(res);
		if (res.code == "200") {
		  
		  sessionStorage.setItem("phone",res.data.phone);
		  sessionStorage.setItem("patientName",res.data.patientName);
		  sessionStorage.setItem("doctorId",res.data.id);
		 alert("登录成功");
		 this.report.showModal()
		} else {
		  alert("账号或密码错误");
		}
		
		
	    this.setState({ name: '', dphoneNumber: '' ,dpassword:""});
	    // 关闭弹窗
	    this.hideModalLogin();
	  };
	 
	  dhandleSubmit =async (e) => {
	    e.preventDefault();
	    // 在这里可以处理提交逻辑，比如将姓名和手机号码发送到后端
	    console.log('姓名:', this.state.dname);
	    console.log('手机号码:', this.state.dphoneNumber);
	    console.log('密码', this.state.dpassword);
	    // 清空输入框
		
		const res = await addDoctor({
		 phone: this.state.dphoneNumber,
		 password: this.state.dpassword,
		  patientName: this.state.dname,
		});
		console.log(res);
		if (res.code == "200") {
		  
		 
		 alert("注册成功");
		 
		} else {
		  alert("账号或密码错误");
		}
	    this.setState({ name: '', dphoneNumber: '' });
	    // 关闭弹窗
	    this.hideModal();
	  };
	  
	  dhandlePasswordChange= (e) => {
	    this.setState({ dpassword: e.target.value });
	  };
  render() {
	  const { isVisible } = this.state;
	  const buttonWidth = 80;
	  const text = <span>Tip</span>;
	  const text1 = <span>组织AI</span>;
	  const text2 = <span>细胞AI </span>;
	  const text3 = <span>免疫组化AI</span>;
	  const text4 = <span>染色体</span>;
	  const text5 = <span>结核</span>;
	  const text6 = <span>远程会诊</span>;
	  const text7 = <span>远程读片会</span>;
	  const text8 = <span>切片扫描仪视频</span>;
	  const content = (
	    <div>
	      <p>Content</p>
	     
	    </div>
	  );
	  const content1 = (
	    <div>
	      <p>胸部AI</p>
	      <p>肺部AI</p>
	      <p>结直肠AI</p>
	      <p>宫颈AI</p>
		  <p>乳腺AI</p>
	      <p>淋巴结AI</p>
	      <p>胃AI</p>
	
	     
	    </div>
	  );
	  const content2 = (
	    <div>
	      <p>TCT </p>
	      <p>DNA倍体   </p>
	     
	    </div>
	  );
	 
	  const content3 = (
	    <div>
	      <p>KI67 AI</p>
	      <p>Her2 AI</p>
	      <p>Er AI</p>
	      <p>Pr AI</p>
	     
	    </div>
	  );
	  const content4 = (
	    <div>
	      <p></p>
			
	    </div>
	  );
	  const content5 = (
	    <div>
	      <p></p>
	     
	    </div>
	  );
	 
	 const content8 = (
	   <div>
	     <ul>
		 <li><a href="https://xytechsz.com/?list_13/86.html" target="_blank">显微图像扫描仪(20倍)</a>  </li>
		 <li><a href="https://xytechsz.com/?list_13/87.html" target="_blank">远程分享</a></li>
		 <li><a href="https://xytechsz.com/?list_13/88.html" target="_blank">宫颈细胞AI分析</a></li>
		 </ul>
	    
	   </div>
	 );
	 const content7 = (
	   <div>
	     <div className="sticky-footer" style={{  width:"400px",    backgroundColor: "#f5f5f5",    padding: "20px" }}>
	       
	       
	     	 <div style={{ display: 'flex', flexDirection: 'row' }}>
	     	      <p style={{ fontSize: "30px", color: "#ed2828", margin: 0 }}>远程读片会</p>
	     	      <div style={{marginTop:"10px",marginBottom:"10px" ,marginLeft:"5px"}}>
	     	             
	     	              <label htmlFor="file-upload-svs" className="custom-file-upload" style={{marginTop:"10px",border :"1px solid #077fff",borderRadius:"5px",color:"#077fff",padding:"5px" ,bordeRadius: "4px",fontSize:"20px" }}>
	     	                上传SVS
	     	                <input id="file-upload-svs" type="file" multiple accept=".svs" style={{ display: "none" }} onChange={this.handleFileChange} />
	     	              </label>
	     	      							   
	     	              <label htmlFor="file-upload-folder" className="custom-file-upload" style={{marginTop:"10px",border :"1px solid #077fff",borderRadius:"5px",color:"#077fff",padding:"5px" ,bordeRadius: "4px",fontSize:"20px" }}>
	     	                上传切片
	     	                <input id="file-upload-folder" type="file"   webkitdirectory="true"   directory="true"    style={{ display: "none" }} onChange={this.handleFileChange2} />
	     	              </label>
	     	           
	     	            
	     	      							  {this.state.uploadProgress > 0 && (
	     	      							     <progress value={this.state.uploadProgress} max="100" style={{ width: "100%", marginTop: "10px" }}>
	     	      							       {this.state.uploadProgress}%
	     	      							     </progress>
	     	      							   )}
	     	      							 </div>
	     									  
	     	    </div>
	       <p style={{marginTop:"5px" ,color:"#df1f1f"}} >限10人观看</p>
	       <hr />
	       
	        
	     		 
	     
	      {this.state.svsList.reverse().map((filePath, index) => (
	          <div key={index}>
	              <p style={{ marginTop: "5px", fontSize: "16px" }}>
	                  <a target="_blank" href={this.extractAfterLastUnderscore3(this.extractAfterLastUnderscore0(filePath))}>
	                      {this.extractAfterLastUnderscore0(filePath)}
	                  </a>
					  <button style={{ marginLeft: "10px" ,color:"red" ,padding:"2px"}} onClick={() => this.handleDownloadLinux(filePath)}>
					      下载
					  </button>
	                  <button style={{ marginLeft: "10px" ,color:"red" ,padding:"2px"}} onClick={() => this.handleDeleteLinux(filePath)}>
	                      删除
	                  </button>
	              </p>
	          </div>
	      ))}
	     <hr />
	      <p style={{ marginTop: "5px", fontSize: "16px" }}>
	          <a target="_blank" href='http://8.130.97.252:5050'>
	              切片在线观看
	          </a>
	          
	      </p>
	      <hr />
	      {this.state.zipList.reverse().map((filePath, index) => (
	          <div key={index}>
	              <p style={{ marginTop: "5px", fontSize: "16px" }}>
	                 
	                      {this.extractAfterLastUnderscore(filePath)}
	                 
	     			 <button style={{ marginLeft: "10px" ,color:"red" ,padding:"2px"}} onClick={() => this.handleDownloadWindows(filePath)}>
	     			     下载
	     			 </button>
	                  <button style={{ marginLeft: "10px" ,color:"red" ,padding:"2px"}} onClick={() => this.handleDeletewindows(filePath)}>
	                      删除
	                  </button>
	              </p>
	          </div>
	      ))}
	     </div>
	 			
	   </div>
	 );
	 
	 
	 
	  
	  const content6 = (
		
	    <div>
			
			
	     <Menu
	             mode="inline"
	             openKeys={this.state.openKeys}
	             onOpenChange={this.onOpenChange}
	             style={{ width: 256 }}
	           >
	             <SubMenu
	               key="sub1"
	               title={
	                 <span>
	                   <Icon type="mail" />
	                   <span>医生</span>
	                 </span>
	               }
	             >
	               <Menu.Item key="1">
	                <div>
	                         <p onClick={this.showModal}>
	                                 注册
	                                </p>
	                               
	                        
	                      </div>
	               </Menu.Item>
	              
	             </SubMenu>
	             <SubMenu
	               key="sub2"
	               title={
	                 <span>
	                   <Icon type="appstore" />
	                   <span>需方</span>
	                 </span>
	               }
	             >
	               <Menu.Item key="5">注册</Menu.Item>
	             </SubMenu> 
	             <SubMenu
	               key="sub4"
	               title={
	                 <span>
	                   <Icon type="setting" />
	                   <span>会诊</span>
	                 </span>
	               }
	             >
	              <Menu.Item key="7">AI初筛</Menu.Item>
	              <Menu.Item key="8">一级会诊</Menu.Item>
	              <Menu.Item key="9">二级会诊</Menu.Item>
	              <Menu.Item key="10">三级会诊</Menu.Item>
	              <Menu.Item key="11">四级会诊</Menu.Item>
	             </SubMenu>
	           </Menu>
	    </div>
	  );
    const {
      login,
      loading,
      localAudioEnabled,
      localVideoEnabled,
      localVideoEnabledBig,
      screenSharingEnabled,
      collapsed,
      vidFit,
    } = this.state;
    return (
      <Layout className="app-layout">
	  <div>
	  <Modal
	            maskClosable={false}
	            mask
	            title="注册"
	            visible={this.state.isModalOpen}
	            footer={null}
	            onCancel={this.handleCancel}
				  style={{ height: '500px' }}
	          >	
			  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '300px' }}>
			         
			           
			       <form>
			        <div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'right' }}>
			        <label >
			          姓    名:
			          <input style={{
			          fontSize: "20px",
			          color: "#000000",
			          marginBottom: '10px',
			          width: '150px',
			          height: '30px',
			          marginLeft: '9px',
			          padding: '5px',
			          borderRadius: '15px',
			          paddingLeft: '13px'
			        }} type="text" value={this.state.dname} onChange={this.dhandleNameChange} />
			        </label></div>
			        <div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'right' }}>
			        <label  >
			           手机号码:
			           <input  style={{
			          fontSize: "20px",
			          color: "#000000",
			          marginBottom: '10px',
			          width: '150px',
			          height: '30px',
			          marginLeft: '9px',
			          padding: '5px',
			          borderRadius: '15px',
			          paddingLeft: '13px'
			        }} type="text" value={this.state.dphoneNumber} onChange={this.dhandlePhoneNumberChange} />
			         </label>
			        </div>
					
					
					
					
					<div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'right' }}>
					<label  >
					   密码:
					   <input  style={{
					  fontSize: "20px",
					  color: "#000000",
					  marginBottom: '10px',
					  width: '150px',
					  height: '30px',
					  marginLeft: '9px',
					  padding: '5px',
					  borderRadius: '15px',
					  paddingLeft: '13px'
					}} type="text" value={this.state.dpassword} onChange={this.dhandlePasswordChange} />
					 </label>
					</div>
					
					
			         <div>
			         </div>
			        <div>
			         <div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'center' }}>
					 <Button type="submit"  onClick={this.dhandleSubmit}>注册</Button>
					 </div>
			        </div>
			       </form>
			        </div>
	            
	          </Modal>
			<Modal
			          maskClosable={false}
			          mask
			          title="登录"
			          visible={this.state.isModalOpenLogin}
			          footer={null}
			          onCancel={this.handleCancelLogin}
							  style={{ height: '500px' }}
			        >	
						  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '300px' }}>
						         
						           
						       <form>
						        
						        <div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'right' }}>
						        <label  >
						           手机号码:
						           <input  style={{
						          fontSize: "20px",
						          color: "#000000",
						          marginBottom: '10px',
						          width: '150px',
						          height: '30px',
						          marginLeft: '9px',
						          padding: '5px',
						          borderRadius: '15px',
						          paddingLeft: '13px'
						        }} type="text" value={this.state.dphoneNumber} onChange={this.dhandlePhoneNumberChange} />
						         </label>
						        </div>
								
								
								
								
								<div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'right' }}>
								<label  >
								   密码:
								   <input  style={{
								  fontSize: "20px",
								  color: "#000000",
								  marginBottom: '10px',
								  width: '150px',
								  height: '30px',
								  marginLeft: '9px',
								  padding: '5px',
								  borderRadius: '15px',
								  paddingLeft: '13px'
								}} type="text" value={this.state.dpassword} onChange={this.dhandlePasswordChange} />
								 </label>
								</div>
								
								
						         <div>
						         </div>
						        <div>
						         <div style={{fontSize:"20px",color:"#000000",marginBottom: '10px', textAlign: 'center' }}>
								 <Button type="submit"  onClick={this.dhandleSubmitLogin}>登录</Button>
								 </div>
						        </div>
						       </form>
						        </div>
			          
			        </Modal>
	  </div>
        {login ? (
			
			
          <Content className="app-center-layout">
			
            <Layout className="app-content-layout">
              <Layout className="app-right-layout">
                <Content style={{ flex: 1 }}>
                 <MyContext.Provider array={this.state.streams}>
                           <MeetingPage
                             startLoop ={this.startLoop}
                             stopLoop ={this.stopLoop }
                             onSendMessage={this.onSendMessage}
                             uid={this.state.uid}
							 hostState={this.state.hostState}
							 hostStatelocal={this.state.hostStatelocal}
                             collapsed={this.state.collapsed}
                             users={this.state.users}
                             settings={this.settings}
                             client={this.client}
                             localAudioEnabled={localAudioEnabled}
                             localVideoEnabled={localVideoEnabled}
                             localVideoEnabledBig={localVideoEnabledBig}
                             screenSharingCallBack={this.onScreenSharingCallBack}
                           					onMediaSettingsChanged={this.onMediaSettingsChanged}
											gx={this.gx}
											ungx={this.ungx}
                             vidFit={vidFit}
                             ref={(ref) => {
                               this.meeting = ref;
                             }}
                           />
                 </MyContext.Provider>
				  
                  <div>
				   {sessionStorage.getItem("yk")==1?"":(
					<div		class="flashing-bar"
					          onMouseEnter={this.handleMouseEnter}
					          onMouseLeave={this.handleMouseLeave}
					          style={{
					            width: '18px',
					            height: '400px',
					            backgroundColor:"#ff0000",
					            position: 'absolute',
								 top: '20%',
								right:"-2px",
								borderRadius:"10px",
								zIndex:'1000'
					          }}
					        >
					          
					          {isVisible && (
					            <div
					              style={{
					                position: 'absolute',
					               
					                right: '50%',
					                transform: 'translateX(-1%)',
					                
					                border: '1px solid #ccc',
					                padding: '10px',
					                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
					                zIndex: 1,
					              }}
					            >
					            <ConfigProvider
					                button={{
					                  style: { width: buttonWidth, margin: 8 },
					                }}
					              >
					                <div className="demo">
					                  
					                  <div style={{ width: "45px", float: 'inline-start' ,marginTop: "10px",marginLeft:"10px",lineHeight:"60px"}}>
					                    <Popover  placement="leftTop" title={text1} content={content1}>
					                   
					            							  <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
					                    </Popover>
					                    <Popover placement="left" title={text2} content={content2}>
					                        <Avatar style={{ backgroundColor: '#1677ff' }} icon={<DeploymentUnitOutlined />} />
					                    </Popover>
					                    <Popover placement="leftBottom" title={text3} content={content3}>
					                       <Avatar style={{ backgroundColor: '#1677ff' }} icon={<FundProjectionScreenOutlined />} />
					                    </Popover>
					            		<Popover placement="leftBottom" title={text4} content={content4}>
					            		   <Avatar style={{ backgroundColor: '#1677ff' }} icon={<PartitionOutlined />} />
					            		</Popover>
					            		
					            		 <Popover placement="leftBottom" title={text5} content={content5}>
					            		    <Avatar style={{ backgroundColor: '#1677ff' }} icon={<SisternodeOutlined />} />
					            		 </Popover>
					            		 
					            		 <Popover placement="leftBottom" title={text6} content={content6} style={{ marginLeft:"-6px !important"}}>
					            		    <Avatar size={45} style={{ backgroundColor: '#1677ff' ,fontSize:"30px"}} icon={<VideoCameraAddOutlined />} />
					            		 </Popover>
					            		 <Popover placement="leftBottom" title={text7} content={content7} style={{ marginLeft:"-6px !important"}}>
					            		    <Avatar size={45} style={{ backgroundColor: '#1677ff' ,fontSize:"30px"}} icon={ <PicRightOutlined />} />
					            		 </Popover>
										 <Popover  placement="leftTop" title={text8} content={content8}>
										 					                   
										 					  <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
										 </Popover>
					                  </div>
					                  
					                </div>
					              </ConfigProvider>
								
								
								
					            </div>
					          )}
					        </div>
						)}	
				  
				  </div>
					
                  <div className="app-bottom-tools-bg"></div>

                  <div className="app-bottom-tools">
                    <Tooltip title="打开关闭面板"></Tooltip>
					 {sessionStorage.getItem("yk")==1?"":(
                    <div className="app-bottom-tool">
						
					  <Radio disabled={this.state.DisMeshOnline}  onClick={this.MeshOnlineClick} value="option1" checked={this.state.MeshOnline ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>网眼上线</span></Radio>
					  <Radio disabled={this.state.DisMirrorOnline}  onClick={this.MirrorOnlineClick} value="option1" checked={this.state.MirrorOnline ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>镜下上线</span></Radio>
					  <Radio disabled={this.state.DisDesktopOnline}  onClick={this.DesktopOnlineClick} value="option1" checked={this.state.DesktopOnline ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>桌面上线</span></Radio>
					  <Radio disabled={this.state.isDisabled}  onClick={this.handleCancelSelection} value="option1" checked={this.state.selectedOption ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>主持人</span></Radio>
                      <Tooltip title="禁音/取消">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localAudioEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleAudioTrackEnabled(!localAudioEnabled)
                          }
                        >
                          <Icon
                            component={
                              localAudioEnabled
                                ? MicrophoneIcon
                                : MicrophoneOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />

                          <p>禁音/取消</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="打开/关闭视频1">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localVideoEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleVideoTrackEnabled(!localVideoEnabled)
                          }
                        >
                          <Icon
                            component={
                              localVideoEnabled ? VideoIcon : VideoOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>打开/关闭视频</p>
                        </Button>
                      </Tooltip>
					  <Tooltip title="共享桌面">
					    <Button
					      ghost
					      size="large"
					      type="link"
					      style={{ color: screenSharingEnabled ? "red" : "" }}
					      onClick={() =>
					        this.handleScreenSharing(!screenSharingEnabled)
					      }
					    >
					      <Icon
					        component={
					          screenSharingEnabled
					            ? TelevisionOffIcon
					            : TelevisionIcon
					        }
					        style={{
					          display: "flex",
					          justifyContent: "center",
					        }}
					      />
					      <p>共享桌面</p>
					    </Button>
					  </Tooltip>
					
                     

                      <Tooltip title="挂断">
                        <Button
                          shape="circle"
                          size="large"
                          type="danger"
                          ghost
                          style={{ marginLeft: 16, marginRight: 16 }}
                          onClick={() => this.hangUp()}
                        >
                          <Icon
                            component={HangupIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Button>
                      </Tooltip>
                      <ShareMeeting loginInfo={this.state.loginInfo} />
                      <MediaSettings
                        onMediaSettingsChanged={this.onMediaSettingsChanged}
                        settings={this.settings}
                      />
                      <PenMeeting
                        pen={this.state.pen}
                        changeColor={this.changeColor}
                        ref={(ref) => {
                          this.penType = ref;
                        }}
                      />

                      <Tooltip title="清除">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          onClick={() => this.onBroomClickHandler()}
                        >
                          <Icon
                            component={BroomIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>清除</p>
                        </Button>
                      </Tooltip>
					  <MyContext.Provider array={this.state.streams}>
					  								<Report
					  								 getImage={this.getImage}	
					  								  onClick={() => this.getReport()}
					  								loginDoctor={this.showModalLogin}				
					  								  onMediaSettingsChanged={this.onMediaSettingsChanged}
					  								  settings={this.settings}
					  								  ref={(ref) => {
					  								    this.report = ref;
					  								  }}
					  								/>	
					            
					  </MyContext.Provider>
					  
					  
					  <Tooltip title="全屏/取消1">
					    <Button
					      ghost
					      size="large"
					      type="link"
					      onClick={() => this.onFullScreenClickHandler()}
					    >
					      <Icon
					        component={
					          this.state.isFullScreen
					            ? FullscreenExitIcon
					            : FullscreenIcon
					        }
					        style={{ display: "flex", justifyContent: "center" }}
					      />
					      <p>全屏/取消1</p>
					    </Button>
					  </Tooltip>
					  <Tooltip title="全屏/取消2">
					    <Button
					      ghost
					      size="large"
					      type="link"
					      onClick={() => this.onFullScreenClickHandler2()}
					    >
					      <Icon
					        component={
					          this.state.isFullScreen
					            ? FullscreenExitIcon
					            : FullscreenIcon
					        }
					       
					      />
					      <p>2全屏/取消</p>
					    </Button>
					  </Tooltip>
                    </div>
					)}	
					 {/* 这是一个注释 
					 
					<Tooltip title="全屏/取消" style={{ display: "flex", justifyContent: "center"  ,marginLeft:"100px"}}>
                      <Button
					     style={{  marginLeft:"100px",marginBottom:"10px"}}
                        ghost
                        size="large"
                        type="link"
                        onClick={() => this.onFullScreenClickHandler2()}
                      >
                        <Icon
                          component={
                            this.state.isFullScreen
                              ? FullscreenExitIcon
                              : FullscreenIcon
                          }
                         
                        />
                        <p>全屏/取消</p>
                      </Button>
                    </Tooltip>
					
					*/}
					
					<div>
						
					</div>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Content>
        ) : loading ? (
          <Spin size="large" tip="正在连接..." />
        ) : (
          <LoginForm handleLogin={this.handleLogin} />
        )}
      </Layout>
    );
  }
}

export default Samples;


 // <Tooltip title="共享桌面">
 //   <Button
 //     ghost
 //     size="large"
 //     type="link"
 //     style={{ color: screenSharingEnabled ? "red" : "" }}
 //     onClick={() =>
 //       this.handleScreenSharing(!screenSharingEnabled)
 //     }
 //   >
 //     <Icon
 //       component={
 //         screenSharingEnabled
 //           ? TelevisionOffIcon
 //           : TelevisionIcon
 //       }
 //       style={{
 //         display: "flex",
 //         justifyContent: "center",
 //       }}
 //     />
 //     <p>共享桌面</p>
 //   </Button>
 // </Tooltip>
 
 
 
 // <Tooltip title="共享桌面">
 //     <Button
 	
 //       ghost
 //       size="large"
 //       type="link"
 //       style={{ color: "grey" }}
      
 //     >
 //       <Icon
 //         component={
 //           screenSharingEnabled
 //             ? TelevisionOffIcon
 //             : TelevisionIcon
 //         }
 //         style={{
 //           display: "flex",
 //           justifyContent: "center",
 //         }}
 //       />
 //       <p>共享桌面</p>
 //     </Button>
 //   </Tooltip>