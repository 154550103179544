import 'babel-polyfill'
import React from "react";
import { Form, Icon, Input, Button, Card, Modal } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";
import { login } from "./api/categories";

 import Vconsole from 'vconsole'
class UserLogin extends React.Component {
  constructor() {
    super();
    this.state = {
		  modelShowY: false,
		      TextY: '',
      login: false,
      loading: false,
      collapsed: false,
      loginInfo: {},
      uid: "",
      roomId: "",
      name: "",
      users: [],
      messages: [],
      localAudioEnabled: true,
      localVideoEnabled: true,
      screenSharingEnabled: false,
      vidFit: false,
      brush: false,
      isFullScreen: false,
      FieldsValue: {
        roomId: "",
        displayName: "",
        displayPassword: "",
      },
      roomForm: {
        username: "",
        password: "",
      },
      models:{
        Text:"",
        modelShow:true,
      },
	  roomList: {
	    roomId: "",
	  
	  },
    };

    this.settings = {
      selectedAudioDevice: "",
      selectedVideoDevice: "",
      resolution: "hd",
      bandwidth: 2048,
      codec: "H264",
    };
    // const vConsole = new Vconsole()


    let settings = reactLocalStorage.getObject("settings");
    if (settings.codec !== undefined) {
      this.settings = settings;
    }
	
	let logostate=this.getCookie("logostatelocal")
	console.log(logostate)
	if(logostate){
		// window.location.href="https://alocal.xytechsz.com/#/RoomList"
	}
  }
  
  componentDidMount = () => {
	  // 清除所有cookie
	  this.removeAllCookies();
	   
	  // 清除所有sessionStorage数据
	  this.clearSessionStorage();
	   
	  // 其他逻辑
    let params = this.getRequest();
    let roomId = "room1";
    let displayName = "";

    let localStorage = reactLocalStorage.getObject("loginInfo");

    if (localStorage) {
      roomId = localStorage.roomId;
      displayName = localStorage.displayName;
    }

    if (params && params.hasOwnProperty("room")) {
      roomId = params.room;
    }
    this.setState({
      FieldsValue: {
        roomId: roomId,
        displayName: displayName,
        displayPassword: "123456",
      },
    });
  };

  componentWillUnmount = () => {
    this.cleanup();
  };
 
 
 removeAllCookies = () => {
     var cookies = document.cookie.split(";");
 
     for (var i = 0; i < cookies.length; i++) {
         var cookie = cookies[i];
         var eqPos = cookie.indexOf("=");
         var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
         document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
     }
 };
 
 clearSessionStorage = () => {
     sessionStorage.clear();
 };		
  cleanup = async () => {};
  userLogin = async (username, password) => {
   

    const res = await login({
      name: username,
      password: password,
    });
    console.log(res);
    if (res.code == "200") {
      sessionStorage.setItem("username",res.data.userName);
      sessionStorage.setItem("userName",res.data.phone);
      sessionStorage.setItem("userId",res.data.id);
      sessionStorage.setItem("bigvideo",1);
      sessionStorage.setItem("times",res.data.times);
      sessionStorage.setItem("activate",res.data.activate);
	  sessionStorage.setItem("token", new Date().getTime());
	  this.setCookie("logostatelocal",1,1)
	  this.setCookie("yk",0)
      this.props.history.push("/RoomList");
    } else {
      alert("账号或密码错误");
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    
    this.userLogin( 
      this.state.roomForm.username,
      this.state.roomForm.password
    );
    // getCategoriesAsync;
    // this.props.history.push("/RoomList");
  };
setCookie =(name, value, iDay)=>{
      var oDate=new Date();
      
      oDate.setDate(oDate.getDate()+iDay);
      
      document.cookie=name+'='+value+';expires='+oDate;
  }
  getCookie=(name)=>{
      var arr=document.cookie.split('; ');
      var i=0;
      for(i=0;i<arr.length;i++)
      {
          var arr2=arr[i].split('=');
          
          if(arr2[0]==name)
          {
              return arr2[1];
          }
      }
      return '';//如果没有就返回空值
  }
 removeCookie=(name)=>{
     this.setCookie(name, 0, -1);//将过期时间设置为昨天，系统就自动删除了
 }
  getRequest() {
    let url = location.search;
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
      let str = url.substr(1);
      let strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }
  inputChangeAdmin(e) {
    this.setState({
      roomForm: {
        username: e.target.value,
        password: this.state.roomForm.password,
      },
    });
  }
  inputChangePassword(e) {
    this.setState({
      roomForm: {
        username: this.state.roomForm.username,
        password: e.target.value,
      },
    });
  }

  handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };  
  
  // 游客登录
  inputChangeRoomName(e) {
    this.setState({
      roomList: {
        roomId: e.target.value,
      },
    });
  }
  roomSubmit = (e) => {
    e.preventDefault();
	// sessionStorage.setItem('roomId',this.state.roomList.roomId );
	// sessionStorage.setItem('yk',1);
 //    this.props.history.push("/Samples")
    this.props.history.push("/VideoPlayer")
  };
  render() {
    const { getFieldDecorator } = this.state;

    return (
      <div className="login-bg">
        <Form
          onSubmit={this.handleSubmit}
          className="login-form"
          style={{ padding: "20px" }}
        >
          <h1 className="login-title">账号用户登录(多人视频诊断协作系统)</h1>

          <Form.Item
            name="displayName"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input
              onChange={(e) => this.inputChangeAdmin(e)}
              prefix={<Icon type="team" className="login-input-icon" />}
              placeholder="用户名"
            />
          </Form.Item>

          <Form.Item
            name="displayPassword"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input
              type="password"
              onChange={(e) => this.inputChangePassword(e)}
              prefix={<Icon type="contacts" className="login-input-icon" />}
              placeholder="请输入密码"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-join-button"
            >
              加入视频诊断
            </Button>
          </Form.Item>
		
        </Form>
		
		<Form
		  onSubmit={this.roomSubmit}
		  className="login-form"
		  style={{ padding: "20px",marginLeft:"10px" }}
		>
		  <h1 className="login-title">游客登录(多人视频诊断协作系统)</h1>
		
		  <Form.Item
		    name="displayName"
		    rules={[{ required: true, message: "请输入房间号" }]}
		  >
		    <Input
		      onChange={(e) => this.inputChangeRoomName(e)}
		      prefix={<Icon type="team" className="login-input-icon" />}
		      placeholder="请输入房间号"
		    />
		  </Form.Item>
		
		 
		
		  <Form.Item>
		    <Button
		      type="primary"
		      htmlType="submit"
		      className="login-join-button"
		    >
		      游客登录
		    </Button>
		  </Form.Item>
		
		</Form>
        <Modal
          title="Title"
          open={this.state.models.modelShow}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>{this.state.models.Text}</p>
        </Modal>
      </div>
    );
  }
}

// const WrappedLoginForm = Form.create({ name: "login" })(UserLogin);
// export default WrappedLoginForm;

export default UserLogin;
