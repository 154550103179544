import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";
import { Route } from "react-router/cjs/react-router.min";
import { selectMeetingNameList, monitorAdd } from "./api/categories";

class UserLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			login: false,
			loading: false,
			collapsed: false,
			loginInfo: {},
			uid: '',
			roomId: '',
			name: '',
			users: [],
			messages: [],
			localAudioEnabled: true,
			localVideoEnabled: true,
			screenSharingEnabled: false,
			vidFit: false,
			brush: false,
			isFullScreen: false,
			FieldsValue: {
				'roomId': "",
				'displayName': "",
				'displayPassword': ""
			},
			MeetingList: [],

		};
		this.getSelectMeetingNameList()
		this.showNavigator()

	}
	showNavigator() {
		const constraints = window.constraints = {
			//禁用音频
			audio: true,
			//启用视频
			video: true
		};
		navigator.mediaDevices.getUserMedia(constraints)
			.then(function (stream) {
				console.log("请求成功")
			})
			.then((devces) => { console.log("请求成功") })
			.catch((erro) => { console.log("请求失败") });
	}
	getSelectMeetingNameList = async () => {



		const res = await selectMeetingNameList({
			userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 25
		});
		console.log(res);
		if (res.code == "200") {
			this.setState({
				MeetingList: res.data
			})

		} else {
			alert("查询失败");
		}
	}
	ToMain = async (msg,roomName) => {
		const res = await monitorAdd({
			userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 25,
			userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userId') : "hw1",
			roomId: msg ? msg : "80001"
		});
		console.log(res);
		if (res.code == "200") {
			sessionStorage.setItem("monitorId", res.data);
			sessionStorage.setItem("toMeetingTime", new Date().getTime());
			sessionStorage.setItem('roomId', msg);
			sessionStorage.setItem('roomName', roomName);
			this.props.history.push("/Samples")
		} else {
			alert("查询失败");
		}

	}
	handleSubmit = e => {
		e.preventDefault();
		


	};



	render() {
		const { getFieldDecorator } = this.state

		return (
			<div className="">
				<div className="div-show1"> 医院列表</div>
				<div className="div-show3">

					{
						this.state.MeetingList.map((item, index) => {
							return index >= 0 ? (
								<div className="div-show" key={item.id} id={item.id} onClick={() => this.ToMain(item.id,item.meetingName)}>{item.meetingName}</div>
							) : (
								""
							);
						})}
				</div>


			</div>
		);
	}
}




export default UserLogin;

